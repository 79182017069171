import { Component } from '@angular/core'
import { FieldType, FieldTypeConfig } from '@ngx-formly/core'

@Component({
  selector: 'app-formly-coordinates',
  templateUrl: './coordinates-field.component.html'
})
export class CoordinatesFieldComponent extends FieldType<FieldTypeConfig> {
  get label(): string {
    return this.props['label'] ?? ''
  }

  get help(): string {
    return this.props['help'] ?? ''
  }

  get floatLabel(): boolean {
    return this.props['floatLabel'] ?? false
  }

  get inputStyleClass(): string {
    return this.props['inputStyleClass'] ?? 'w-full'
  }

  get placeholder(): string {
    return this.props['placeholder'] ?? ''
  }

  get required(): boolean {
    return this.props['required'] ?? false
  }

  get readOnly(): boolean {
    return this.props['readonly'] ?? this.props['readOnly'] ?? false
  }
}
