import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormlyModule } from '@ngx-formly/core'
import { ReactiveFormsModule } from '@angular/forms'

import { SatinelSelectModule } from '@satinel-system/core'

import { AxisFieldComponent } from './axis-field.component'

@NgModule({
  declarations: [AxisFieldComponent],
  imports: [
    FormlyModule,
    CommonModule,
    ReactiveFormsModule,
    SatinelSelectModule
  ],
  exports: [AxisFieldComponent]
})
export class AxisFieldModule {}
