import { CoordinatesFieldComponent } from './coordinates-field/coordinates-field.component'
import { AdressFieldComponent } from './address-field/address-field.component'
import { AxisFieldComponent } from './axis-field/axis-field.component'

// Components
export * from './address-field/address-field.module'
export * from './address-field/address-field.component'
export * from './axis-field/axis-field.module'
export * from './axis-field/axis-field.component'
export * from './coordinates-field/coordinates-field.module'
export * from './coordinates-field/coordinates-field.component'

// Module
export { FormExtensionsModule } from './form-extensions.module'

export const formExtensions = {
  types: [
    {
      name: 'address',
      component: AdressFieldComponent
    },
    {
      name: 'axis',
      component: AxisFieldComponent
    },
    {
      name: 'coordinates',
      component: CoordinatesFieldComponent
    }
  ]
}
