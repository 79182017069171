// Angular
import { AppComponent } from './app.component'
import {
  NgModule,
  isDevMode,
  ErrorHandler,
  APP_INITIALIZER
} from '@angular/core'
import { Router } from '@angular/router'
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http'
import { ReactiveFormsModule } from '@angular/forms'
import { ServiceWorkerModule } from '@angular/service-worker'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

// NgRx
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import {
  reducers,
  AuthEffects,
  metaReducers,
  LocalStorageEffects
} from '@goplanner/module-builder'

// Data Store API Client Library
import { goplannerStoreModule } from '@goplanner/api-client'

// Locale
import { LOCALE_ID } from '@angular/core'
import localeEs from '@angular/common/locales/es'
import { APP_BASE_HREF, registerLocaleData } from '@angular/common'

// Registra el idioma español
registerLocaleData(localeEs, 'es')

// Satinel UI Library
import { SatinelFormModule } from '@satinel-system/form'

// Form Extensions
import {
  FormExtensionsModule,
  formExtensions
} from './shared/components/form-extensions'

// PrimeNG
import { ToastModule } from 'primeng/toast'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { ConfirmationService, MessageService } from 'primeng/api'

// Routing
import { AppRoutingModule, routerProvider } from './app-routing.module'

// Interceptors
import { AuthInterceptor } from './shared/interceptors/auth.interceptor'

// Transloco
import { TranslocoRootModule } from './transloco-root.module'

// Environment
import { environment } from '../environments/environment'

// Sentry
import * as Sentry from '@sentry/angular'

const primeng = [ToastModule, ConfirmDialogModule]

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      // Comprobaciones en tiempo de ejecución para los estados de ngrx
      runtimeChecks: {
        // Comprobaciones de inmutabilidad
        strictStateImmutability: true,
        strictActionImmutability: true,
        // Comprobaciones de serialización
        strictStateSerializability: true,
        strictActionSerializability: true,
        // La acción se ejecuta dentro de la zona de Angular
        strictActionWithinNgZone: true,
        // La acción es única
        strictActionTypeUniqueness: true
      }
    }),
    goplannerStoreModule.forRoot({
      apiUrl: environment.api.url
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    EffectsModule.forRoot([AuthEffects, LocalStorageEffects]),
    StoreRouterConnectingModule.forRoot(),
    FormExtensionsModule.forRoot(),
    SatinelFormModule.forRoot(formExtensions),
    TranslocoRootModule,
    ...primeng
  ],
  providers: [
    Location,
    MessageService,
    ConfirmationService,
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: LOCALE_ID,
      useValue:
        navigator.languages && navigator.languages.length
          ? navigator.languages[0]
          : (navigator.language ?? 'es')
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi()),
    routerProvider
  ]
})
export class AppModule {}
