<satinel-system-select
  [inputId]="id"
  [formlyField]="field"
  [formControl]="formControl"
  [options]="(dropdownItems | async) || []"
  [optionLabel]="[
    '#pais__pais_siglas',
    'alias_direccion',
    'nombre',
    '&',
    'codigo_erp',
    'direccion_formateada'
  ]"
  optionValue="id"
  [placeholder]="placeholder"
  [showClear]="showClear"
  [lazy]="lazy"
  [help]="help"
  [label]="label"
  [floatLabel]="floatLabel"
  [showFilter]="showFilter"
  [styleClass]="styleClass"
  [appendTo]="appendTo"
  [scrollHeight]="scrollHeight"
  [itemTemplate]="itemTemplate"
  [selectedItemTemplate]="selectedItemTemplate"
  [groupTpl]="groupTemplate"
  [readOnly]="readOnly"
  [required]="required"
  [configItems]="configItems"
  (lazyLoad)="lazyLoad($event)"
  (filter)="filter($event)"
  (sort)="sort($event)"
  [loading]="(loadingObservable | async) || false"
/>

<p-overlayPanel #configOptions>
  <div class="flex flex-column">
    @for (opcion of typeFilterItems; track opcion; let idx = $index) {
      <div
        class="flex gap-2 align-items-center cursor-pointer px-2 py-1 border-round"
        (click)="changeTypeFilter(opcion.value)"
      >
        <div class="pointer-events-none">
          <p-checkbox
            [(ngModel)]="typeFilterValues[opcion.value]"
            [binary]="true"
          />
        </div>
        <div
          class="flex align-items-center justify-content-center h-2rem w-2rem border-round bg-blue-100"
        >
          <i class="{{ opcion.icon }}"></i>
        </div>
        <div class="line-height-1">
          <div class="font-bold">{{ opcion.label }}</div>
          <small>{{ opcion.description }}</small>
        </div>
      </div>
      @if (idx < typeFilterItems.length - 1) {
        <p-divider styleClass="mx-0 my-1"></p-divider>
      }
    }
  </div>
</p-overlayPanel>
