import { NgModule } from '@angular/core'
import { FormlyModule } from '@ngx-formly/core'
import { ReactiveFormsModule } from '@angular/forms'

import { SatinelInputCoordinatesModule } from '@satinel-system/map'

import { CoordinatesFieldComponent } from './coordinates-field.component'

@NgModule({
  declarations: [CoordinatesFieldComponent],
  imports: [FormlyModule, ReactiveFormsModule, SatinelInputCoordinatesModule],
  exports: [CoordinatesFieldComponent]
})
export class CoordinatesFieldModule {}
