import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import {
  HttpErrorResponse,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http'
import { Observable, Subject, throwError, catchError, debounceTime } from 'rxjs'
import { Store } from '@ngrx/store'

import { State, logoutSuccess } from '@goplanner/module-builder'
import { MessageService } from 'primeng/api'

/**
 * Interceptor to handle 401 errors
 * Redirects to login page
 * Shows a modal
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private errorSubject = new Subject<{
    error: HttpErrorResponse
    req: HttpRequest<unknown>
  }>()
  private error$ = this.errorSubject.asObservable().pipe(debounceTime(100))

  /**
   * Constructor
   * @param router Router
   * @param messageService MessageService
   */
  constructor(
    private router: Router,
    private store: Store<State>,
    private messageService: MessageService
  ) {
    this.error$.subscribe(({ error, req }) => {
      if (error.status === 401 && !req.url.includes('login'))
        this.messageService.add({
          severity: 'warn',
          summary: 'Sesión caducada',
          detail: 'La sesión ha caducado, por favor, vuelva a iniciar sesión'
        })
      else if (error.status === 403)
        this.messageService.add({
          severity: 'warn',
          summary: 'Acceso denegado',
          detail: 'No tiene permisos para acceder a esta página'
        })
      else if (error.status === 404)
        this.messageService.add({
          severity: 'warn',
          summary: 'Página no encontrada',
          detail: 'La página solicitada no existe'
        })
    })
  }

  /**
   * Intercepts the request
   * @param req the request
   * @param next the next handler
   * @returns the response observable
   */
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !req.url.includes('login')) {
          // redirect to the login route
          localStorage.removeItem('auth')
          this.store.dispatch(logoutSuccess())
          this.router.navigate(['/login'])
        } else if (error.status === 403) {
          // redirect to the home route
          this.router.navigate(['/'])
        } else if (error.status === 404) {
          // redirect to the not found route and ignore the location that was not found
          this.router.navigate(['/404'], {
            skipLocationChange: true
          })
        }
        this.errorSubject.next({ error, req })
        return throwError(() => error)
      })
    )
  }
}
