import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { provideAnimations } from '@angular/platform-browser/animations'
import { enableProdMode } from '@angular/core'
import * as Sentry from '@sentry/angular'

import { environment } from './environments/environment'
import packageJson from 'package.json'

import { AppModule } from './app/app.module'

Sentry.init({
  dsn: 'https://ce37c8f9e116dbb40105bac24af192af@o4507628114411520.ingest.de.sentry.io/4507628153995344',
  integrations: [
    Sentry.browserTracingIntegration()
    //Sentry.replayIntegration()
  ],
  tracePropagationTargets: [
    environment.baseUrl + environment.api.url,
    environment.baseUrl
  ],
  sendClientReports: false,
  release: packageJson.version,
  enabled: environment.production, // Set to 'true' to enable Sentry in your application
  environment: environment.production ? 'production' : 'development',
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// Enable production mode
if (environment.production) enableProdMode()

// Bootstrap the application
platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    providers: [provideAnimations()]
  })
  .catch((err) => console.error(err))
