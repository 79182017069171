import { NgModule } from '@angular/core'

import { AdressFieldModule } from './address-field/address-field.module'
import { AxisFieldModule } from './axis-field/axis-field.module'
import { CoordinatesFieldModule } from './coordinates-field/coordinates-field.module'

@NgModule({
  imports: [AdressFieldModule, AxisFieldModule, CoordinatesFieldModule]
})
export class FormExtensionsModule {
  static forRoot() {
    return {
      ngModule: FormExtensionsModule,
      providers: []
    }
  }
}
