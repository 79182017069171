import { Component } from '@angular/core'
import { animate, style, transition, trigger } from '@angular/animations'

import { NavigationService } from './shared/services/navigation.service'
import { TranslateService } from './shared/services/translate.service'
import { ThemeService } from './shared/services/theme.service'
import { AuthService } from '@goplanner/module-builder'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('enter', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class AppComponent {
  /**
   * constructor
   * @param navigationService Navigation Service
   * @param translateService Translate Service
   * @param themeService Theme Service
   * @param authService Auth Service
   */
  constructor(
    _navigationService: NavigationService,
    translateService: TranslateService,
    themeService: ThemeService,
    authService: AuthService
  ) {
    // Initialize auth service
    authService.init()

    // Initialize translate service
    translateService.init()

    // Initialize theme service
    themeService.init()
  }
}
