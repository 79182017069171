import { inject, NgModule } from '@angular/core'
import {
  Router,
  Routes,
  RouterModule,
  provideRouter,
  withViewTransitions
} from '@angular/router'
import { QuicklinkStrategy } from 'ngx-quicklink'

import { AuthGuard } from './shared/guards/auth.guard'

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard]
  }
]

export const routerProvider = provideRouter(
  routes,
  withViewTransitions({
    onViewTransitionCreated: ({ transition }) => {
      const router = inject(Router)
      const targetUrl = router.getCurrentNavigation()!.finalUrl!
      // Skip the transition if the only thing
      // changing is the fragment and queryParams
      const config = {
        paths: 'exact',
        matrixParams: 'exact',
        fragment: 'ignored',
        queryParams: 'ignored'
      } as const

      if (
        router.isActive(targetUrl, config) ||
        targetUrl.toString().startsWith('/login')
      )
        transition.skipTransition()
    }
  })
)

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: QuicklinkStrategy,
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
