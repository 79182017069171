import {
  ViewContainerRef,
  ComponentRef,
  DestroyRef,
  Component,
  ViewChild,
  inject,
  OnInit
} from '@angular/core'
import { BehaviorSubject, debounceTime, Observable } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

import { OverlayPanel } from 'primeng/overlaypanel'

import { FormlyFieldConfig, SelectComponent } from '@satinel-system/form'

import { DropdownService } from '@goplanner/form-builder'
import {
  EntityInterfaces,
  BackendService,
  FilterParam
} from '@goplanner/api-client'

import { DialogAddressComponent } from 'src/app/shared/components/dialog-address/dialog-address.component'

type TypeFilterItem = {
  icon: string
  label: string
  description: string
  value: string
}

@Component({
  selector: 'app-formly-address',
  templateUrl: './address-field.component.html'
})
export class AdressFieldComponent extends SelectComponent implements OnInit {
  readonly destroy = inject(DestroyRef)

  @ViewChild('configOptions') configOptions!: OverlayPanel

  search: ComponentRef<DialogAddressComponent> | undefined
  selectToAssociateTranslationKey = 'goplanner.COMMON.SELECT'

  foreign = {
    fields:
      '#pais__pais_siglas,alias_direccion,nombre,&,codigo_erp,direccion_formateada',
    table: 'ent_direcciones',
    key: 'id'
  }

  configItemActions: {
    icon: string
    styleClass?: string
    action: (item: FormlyFieldConfig) => void
  }[] = []

  typeFilterItems: TypeFilterItem[] = [
    {
      icon: 'pi pi-filter-slash',
      label: 'No filtrar',
      description: 'Mostrar todas las direcciones',
      value: 'reset_filter'
    },
    {
      icon: 'pi pi-user',
      label: 'Por cliente',
      description: 'Filtrar las direcciones por el cliente',
      value: 'filterCliente'
    },
    {
      icon: 'pi pi-home',
      label: 'Sólo propias',
      description: 'Mostrar únicamente direcciones propias del cliente',
      value: 'filterPropias'
    }
  ]

  typeFilterValues: { [key: string]: boolean } = {
    filterCliente: true
  }

  override get configItems() {
    return this.configItemActions
  }

  override get lazyLoad() {
    return (event: { first: number; last: number }) =>
      this.dropdown.lazyLoad(
        `ent_direcciones_${this.id}`,
        'ent_direcciones',
        this.foreign,
        this.backend,
        event
      )
  }

  override get filter() {
    return (event: { originalEvent: Event; filter: any }) =>
      this.dropdown.filter(
        `ent_direcciones_${this.id}`,
        'ent_direcciones',
        this.foreign,
        this.backend,
        event
      )
  }

  override get sort() {
    return (sort: { type: 'ASC' | 'DESC' }) => {
      this.dropdown.sort(
        `ent_direcciones_${this.id}`,
        'ent_direcciones',
        this.foreign,
        this.backend,
        sort,
        true
      )
    }
  }

  get clientField(): string | undefined {
    return this.props['clientField']
  }

  idCliente: number | undefined

  loadingObservable!: Observable<boolean>

  dropdownItems!: Observable<EntityInterfaces['ent_direcciones']['get'][]>

  filterParams: (FilterParam<'ent_direcciones'> | 'OR' | 'AND')[] = [
    {
      field: 'activo',
      operator: '=',
      value: true
    }
  ]

  constructor(
    private vcr: ViewContainerRef,
    private dropdown: DropdownService,
    private backend: BackendService
  ) {
    super()
  }

  ngOnInit() {
    // recupero los datos de localStorage
    const filterDireccion = localStorage.getItem(
      'ent_direcciones_filterCliente'
    )
    try {
      // Intenta parsear solo si es una cadena JSON válida
      if (filterDireccion) this.typeFilterValues = JSON.parse(filterDireccion)
    } catch (error) {
      console.error('Error parsing JSON:', error)
    }

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((values) => {
        if (
          this.clientField &&
          this.clientField in values &&
          values[this.clientField]
        ) {
          // Cambia valor del cliente
          if (this.idCliente === values[this.clientField]) return
          this.idCliente = values[this.clientField]
          if (
            this.typeFilterValues['filterCliente'] ||
            this.typeFilterValues['filterPropias']
          )
            this.adjustFilterParams()
        } else if (this.clientField && this.clientField in values) {
          // Se resetea el cliente
          this.idCliente = undefined
          if (
            this.typeFilterValues['filterCliente'] ||
            this.typeFilterValues['filterPropias']
          )
            this.adjustFilterParams()
        }
      })

    if (this.props['showAddressConfig']) {
      this.configItemActions = [
        {
          icon: 'fas fa-filter-list',
          action: this.openConfig.bind(this) as any
        },
        {
          icon: 'fas fa-map-location-dot',
          action: this.openSearchAddress.bind(this)
        }
      ]
    } else {
      this.configItemActions = [
        {
          icon: 'fas fa-map-location-dot',
          action: this.openSearchAddress.bind(this)
        }
      ]
    }

    this.dropdown.loadingSubject[`ent_direcciones_${this.id}`] =
      new BehaviorSubject<boolean>(true)
    this.loadingObservable =
      this.dropdown.loadingSubject[`ent_direcciones_${this.id}`].asObservable()

    if (this.clientField) {
      const clientValue = this.form.value[this.clientField]
      if (clientValue) {
        this.idCliente = clientValue
        this.filterParams = this.getFilterParams()
      }
    }

    this.dropdownItems = this.dropdown.getOptions(
      `ent_direcciones_${this.id}`,
      'ent_direcciones',
      this.foreign,
      this.backend,
      this.filterParams,
      this.formControl?.value as number | number[],
      true
    )
  }

  getFilterParams(): FilterParam<'ent_direcciones'>[] {
    return [
      {
        field: 'activo',
        operator: '=',
        value: true
      },
      ...(this.idCliente && this.typeFilterValues['filterPropias']
        ? ([
            {
              field: 'id_cliente',
              operator: '=',
              value: this.idCliente
            }
          ] as FilterParam<'ent_direcciones'>[])
        : this.idCliente && this.typeFilterValues['filterCliente']
          ? ([
              'OR',
              {
                field: 'id_cliente',
                operator: '=',
                value: this.idCliente
              },
              {
                field: 'cli_direccion_entrega__id_cliente',
                operator: '=',
                value: this.idCliente
              },
              'AND'
            ] as FilterParam<'ent_direcciones'>[])
          : [])
    ]
  }

  changeTypeFilter(value: string) {
    // Si no se proporciona un valor, termina la función
    if (!value) return

    // Cambia el valor del tipo de filtro
    Object.keys(this.typeFilterValues).forEach((key) => {
      if (key === value) {
        this.typeFilterValues[key] = !this.typeFilterValues[key]
      } else {
        this.typeFilterValues[key] = false
      }
    })

    if (!Object.values(this.typeFilterValues).some((v) => v)) {
      // Si no hay ningún filtro activo, se resetea el filtro
      this.typeFilterValues['reset_filter'] = true
    }

    // Ajusta los parámetros del filtro
    this.adjustFilterParams()
    // Guarda el estado del filtro
    this.saveFilterState()
  }

  saveFilterState() {
    // Guarda el estado actual del filtro en el almacenamiento local
    localStorage.setItem(
      'ent_direcciones_filterCliente',
      JSON.stringify(this.typeFilterValues)
    )
  }

  adjustFilterParams() {
    this.filterParams = this.getFilterParams()
    this.dropdown.applyBaseFilter(
      `ent_direcciones_${this.id}`,
      'ent_direcciones',
      this.foreign,
      this.backend,
      this.filterParams,
      true
    )
  }

  /**
   * Open the search address dialog for the field
   * @param field the field to open the search dialog for
   * @param fieldModel the field model
   */
  openSearchAddress(): void {
    this.search = this.vcr.createComponent(DialogAddressComponent)
    const searchInstance = this.search.instance
    searchInstance.visible = true
    searchInstance.saveButtonIcon = 'pi pi-check'
    searchInstance.selectToAssociateTranslationKey =
      this.selectToAssociateTranslationKey
    searchInstance.saveButtonSeverity = 'success'
    searchInstance.valueField = 'id'
    searchInstance.value = this.formControl?.value as number | number[]
    searchInstance.baseFilter = this.filterParams
    searchInstance.excludeFilters =
      this.typeFilterValues['filterCliente'] && this.idCliente
        ? ['activo', 'id_cliente', 'cli_direccion_entrega__id_cliente']
        : this.typeFilterValues['filterPropias'] && this.idCliente
          ? ['activo', 'id_cliente']
          : ['activo']
    searchInstance.baseRecord = this.idCliente
      ? { id_cliente: this.idCliente }
      : {}

    const afterSave = searchInstance.saved
      .pipe(takeUntilDestroyed(this.destroy), debounceTime(200))
      .subscribe((selected) => {
        const value = Array.isArray(selected)
          ? selected.map((s) => s['id'])
          : selected['id']
        if (!value) return
        this.dropdown.applyValue(`ent_direcciones_${this.id}`, value, true)
        setTimeout(() => {
          this.formControl.setValue(value)
          this.options.formState = {
            ...this.options.formState,
            [this.field.key as string]: value
          }
        }, 0)
        this.search?.instance.onDestroy()
      })

    const destroy = searchInstance.destroy
      .pipe(takeUntilDestroyed(this.destroy), debounceTime(200))
      .subscribe(() => {
        afterSave?.unsubscribe()
        destroy?.unsubscribe()
        this.search?.destroy()
        this.search = undefined
      })
  }

  openConfig(_: any, event: any): void {
    this.configOptions.toggle(event)
  }
}
